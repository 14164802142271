import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const Input = styled('input')({
    display: 'none',
});

function Karyawan(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_karyawan, setSumberKaryawan] = useState([]);
    const [data_karyawan, setDataKaryawan] = useState([]);
    const [id_karyawan, setIdKaryawan] = useState("");
    const [nik, setNik] = useState("");
    const [nama, setNama] = useState("");
    const [tempat_lahir, setTempatLahir] = useState("");
    const [tanggal_lahir, setTanggalLahir] = useState(null);
    const [jk, setJk] = useState("");
    const [gol_darah, setGolDarah] = useState("");
    const [alamat, setAlamat] = useState("");
    const [agama, setAgama] = useState("");
    const [status_kawin, setStatusKawin] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");
    const [tampung_foto, setTampungFoto] = useState(null);
    const [foto, setFoto] = useState(null);
    const [jenis_file, setJenisFile] = useState("");
    const [nama_foto, setNamaFoto] = useState("");
    const [tampil_dialog_hapus, setTampilDialogHapus] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberKaryawan(data.data);
                    setDataKaryawan(data.data);
                }
                else {
                    setSumberKaryawan([]);
                    setDataKaryawan([]);
                }
            })
            .catch(error => {
                setSumberKaryawan([]);
                setDataKaryawan([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_karyawan = () => {
        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberKaryawan(data.data);
                    setDataKaryawan(data.data);
                }
                else {
                    setSumberKaryawan([]);
                    setDataKaryawan([]);
                }
            })
            .catch(error => {
                setSumberKaryawan([]);
                setDataKaryawan([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_karyawan.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_karyawan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_karyawan[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_karyawan[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataKaryawan(tampung);
    }

    const ubah_nik = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setNik(event.target.value);
    }

    const bersih_input_karyawan = () => {
        setIdKaryawan("");
        setNik("");
        setNama("");
        setTempatLahir("");
        setTanggalLahir(null);
        setJk("1");
        setGolDarah("");
        setAlamat("");
        setAgama("");
        setStatusKawin("");
        setNoTelpon("");
        setStatus(false);
        setLabelStatus("Nonaktif");
        setTampungFoto(null);
        setFoto(null);
        setJenisFile("");
        setNamaFoto("");
    }

    const simpan = () => {
        if (nik.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("NIK wajib diisi");
            setTampilNotif(true);
            document.getElementById("nik").focus();
            return;
        }

        if (nama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (tempat_lahir.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Tempat lahir wajib diisi");
            setTampilNotif(true);
            document.getElementById("tempat_lahir").focus();
            return;
        }

        if (tanggal_lahir === null) {
            setJenisNotif("warning");
            setIsiNotif("Tanggal lahir wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (jk.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Jenis kelamin wajib diisi");
            setTampilNotif(true);
            document.getElementById("jk").focus();
            return;
        }

        if (alamat.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Alamat wajib diisi");
            setTampilNotif(true);
            document.getElementById("alamat").focus();
            return;
        }

        if (agama.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Agama wajib diisi");
            setTampilNotif(true);
            document.getElementById("agama").focus();
            return;
        }

        var tahun = String(tanggal_lahir.getFullYear());
        var bulan = String(tanggal_lahir.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(tanggal_lahir.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_tanggal_lahir = tahun + "-" + bulan + "-" + tanggal;

        if (id_karyawan === "") {
            fetch(props.aplikasi + '/karyawan/simpan_karyawan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_karyawan: "",
                            nik: nik,
                            nama: nama,
                            tempat_lahir: tempat_lahir,
                            tanggal_lahir: format_tanggal_lahir,
                            jk: jk,
                            gol_darah: gol_darah,
                            alamat: alamat,
                            agama: agama,
                            status_kawin: status_kawin,
                            no_telpon: no_telpon,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_karyawan();
                        tampil_karyawan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_karyawan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_karyawan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
        else {
            fetch(props.aplikasi + '/karyawan/simpan_karyawan.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_karyawan: id_karyawan,
                            nik: nik,
                            nama: nama,
                            tempat_lahir: tempat_lahir,
                            tanggal_lahir: format_tanggal_lahir,
                            jk: jk,
                            gol_darah: gol_darah,
                            alamat: alamat,
                            agama: agama,
                            status_kawin: status_kawin,
                            no_telpon: no_telpon,
                            status: status,
                            foto: foto,
                            jenis_file: jenis_file
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_karyawan();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                    else {
                        tampil_karyawan();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                    }
                })
                .catch(error => {
                    tampil_karyawan();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                }
                );
        }
    }

    const hapus = () => {
        setProses(true);
        fetch(props.aplikasi + '/karyawan/hapus_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_karyawan: id_karyawan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_karyawan();
                    tampil_karyawan();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                else {
                    tampil_karyawan();
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
            })
            .catch(error => {
                tampil_karyawan();
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                document.getElementById("nama").focus();
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_karyawan();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama karyawan"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_karyawan.map((data_karyawan) => {
                        return (
                            <Grid
                                item xs={6} md={3} lg={2}
                                key={data_karyawan.id_karyawan}
                                onClick={() => {
                                    bersih_input_karyawan();
                                    setIdKaryawan(data_karyawan.id_karyawan);
                                    setNik(data_karyawan.nik);
                                    setNama(data_karyawan.nama);
                                    setTempatLahir(data_karyawan.tempat_lahir);
                                    let tahun = (data_karyawan.tanggal_lahir).substring(0,4);
                                    let bulan = (data_karyawan.tanggal_lahir).substring(5,7);
                                    let hari = (data_karyawan.tanggal_lahir).substring(8,10);                                    
                                    setTanggalLahir(new Date(tahun, bulan, hari));
                                    setJk(data_karyawan.jk);
                                    setGolDarah(data_karyawan.gol_darah);
                                    setAlamat(data_karyawan.alamat);
                                    setAgama(data_karyawan.agama);
                                    setStatusKawin(data_karyawan.status_kawin);
                                    setNoTelpon(data_karyawan.no_telpon);
                                    if (data_karyawan.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }
                                    setNamaFoto(data_karyawan.foto);
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemAvatar>
                                                <Avatar
                                                    src={props.file + "/Foto/Karyawan/" + data_karyawan.foto}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="body1" noWrap>{data_karyawan.nama}</Typography>
                                                }
                                                secondary={
                                                    <div>
                                                        <Typography variant="subtitle1" noWrap>{data_karyawan.alamat}</Typography>
                                                        <Typography variant="body2" noWrap>{data_karyawan.no_telpon}</Typography>
                                                    </div>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                maxWidth="lg"
                fullWidth
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Karyawan</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <center>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={
                                    <label htmlFor="icon-button-file">
                                        <Input accept="image/*" id="icon-button-file" type="file"
                                            onChange={(e) => {
                                                setFoto(e.target.files[0]);
                                                setTampungFoto(e.target.files[0]);
                                                setJenisFile(e.target.files[0].name.split(".").pop());
                                                let reader = new FileReader();
                                                reader.readAsDataURL(e.target.files[0]);
                                                reader.onload = () => {
                                                    setFoto(reader.result);
                                                }
                                            }}
                                        />
                                        <Fab
                                            size="small"
                                            color="primary"
                                            component="span"
                                        >
                                            <PhotoCamera />
                                        </Fab>
                                    </label>
                                }
                            >
                                <Avatar
                                    src={tampung_foto !== null ? URL.createObjectURL(tampung_foto) : props.file + "/Foto/Karyawan/" + nama_foto}
                                    sx={{ width: 128, height: 128 }}
                                />
                            </Badge>
                        </center>
                        <br />
                        <Grid container spacing={1}>
                            <Grid container item spacing={1} xs={6}>
                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            NIK
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <TextField
                                            id="nik"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={nik}
                                            onChange={(event) => ubah_nik(event)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Nama Lengkap
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <TextField
                                            id="nama"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={nama}
                                            onChange={(event) => setNama(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Tempat Lahir
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <TextField
                                            id="tempat_lahir"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={tempat_lahir}
                                            onChange={(event) => setTempatLahir(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Tanggal Lahir
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                id="tanggal_lahir"
                                                inputFormat="dd-MM-yyyy"
                                                value={tanggal_lahir}
                                                onChange={(newValue) => {
                                                    setTanggalLahir(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Jenis Kelamin
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <RadioGroup
                                            row
                                            defaultValue="1"
                                            value={jk}
                                            onChange={(event) => setJk(event.target.value)}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Laki-Laki" />
                                            <FormControlLabel value="0" control={<Radio />} label="Perempuan" />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item spacing={1} xs={6}>
                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Golongan Darah
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="gol_darah"
                                                variant="outlined"
                                                fullWidth
                                                value={gol_darah}
                                                onChange={(event) => setGolDarah(event.target.value)}
                                            >
                                                <MenuItem value="">Tidak Ditentukan</MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="O">O</MenuItem>
                                                <MenuItem value="AB">AB</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Alamat
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <TextField
                                            id="alamat"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={alamat}
                                            onChange={(event) => setAlamat(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Agama
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="agama"
                                                variant="outlined"
                                                fullWidth
                                                value={agama}
                                                onChange={(event) => setAgama(event.target.value)}
                                            >
                                                <MenuItem value="">Tidak Ditentukan</MenuItem>
                                                <MenuItem value="ISLAM">Islam</MenuItem>
                                                <MenuItem value="KATHOLIK">Katholik</MenuItem>
                                                <MenuItem value="PROTESTAN">Protestan</MenuItem>
                                                <MenuItem value="HINDU">Hindu</MenuItem>
                                                <MenuItem value="BUDHA">Budha</MenuItem>
                                                <MenuItem value="LAINNYA">Lainnya</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Status Kawin
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                id="status_kawin"
                                                variant="outlined"
                                                fullWidth
                                                value={status_kawin}
                                                onChange={(event) => setStatusKawin(event.target.value)}
                                            >
                                                <MenuItem value="">Tidak Ditentukan</MenuItem>
                                                <MenuItem value="BELUM MENIKAH">Belum Menikah</MenuItem>
                                                <MenuItem value="MENIKAH">Menikah</MenuItem>
                                                <MenuItem value="DUDA / JANDA">Duda / Janda</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={5} sm={4} md={3}>
                                        <Typography variant="body2">
                                            No. Telpon
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={7} sm={8} md={9}>
                                        <TextField
                                            id="no_telpon"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            value={no_telpon}
                                            onChange={(event) => setNoTelpon(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container item>
                                    <Grid item xs={4} sm={4} md={3}>
                                        <Typography variant="body2">
                                            Status
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={8} sm={8} md={9}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={status}
                                                    onChange={(event) => {
                                                        if (status === false) {
                                                            setStatus(true);
                                                            setLabelStatus("Aktif");
                                                        }
                                                        else {
                                                            setStatus(false);
                                                            setLabelStatus("Nonaktif");
                                                        }
                                                    }}
                                                />
                                            }

                                            label={label_status}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography variant="body2" noWrap={true}>{id_karyawan === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<DeleteIcon />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "red" }}
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(true);
                        }}
                    >
                        <Typography variant="body2" noWrap={true}>Hapus</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tampil_dialog_hapus}
                onClose={() => setTampilDialogHapus(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Hapus Karyawan
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin menghapus data ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton 
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialogHapus(false)} 
                        autoFocus
                    >
                        Tidak
                    </LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => {
                            setTampilDialogHapus(false);
                            setTampilDialog(false);
                            hapus();
                        }}
                    >
                        Ya
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Karyawan);